
.slick-slider>div{
  margin: 0 2px;
}

.bordered-text {
  border: 3px dashed rgb(128, 0, 0);
  border-radius: 10px;
  background-color: rgba(0,0,0,0.75);
  padding: 4px;
  margin: 20px;
}

